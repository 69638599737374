@font-face {
  font-family: Flaticon;
  src: url("Flaticon.95cc49fa.eot");
  src: url("Flaticon.95cc49fa.eot#iefix") format("embedded-opentype"), url("Flaticon.2d265d83.woff") format("woff"), url("Flaticon.1a5c3e51.ttf") format("truetype"), url("Flaticon.0b4e8398.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-device-pixel-ratio >= 0) {
  @font-face {
    font-family: Flaticon;
    src: url("Flaticon.0b4e8398.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before, [class^="flaticon-"]:after, [class*=" flaticon-"]:after {
  font-family: Flaticon;
  font-style: normal;
}

.flaticon-computing-code:before {
  content: "";
}

.flaticon-cup:before {
  content: "";
}

.flaticon-cup-1:before {
  content: "";
}

.flaticon-database:before {
  content: "";
}

.flaticon-edit:before {
  content: "";
}

.flaticon-emoticon-square-smiling-face-with-closed-eyes:before {
  content: "";
}

.flaticon-folder:before {
  content: "";
}

.flaticon-heart:before {
  content: "";
}

.flaticon-light-bulb:before {
  content: "";
}

.flaticon-picture:before {
  content: "";
}

.flaticon-settings:before {
  content: "";
}

.flaticon-square:before {
  content: "";
}

.flaticon-technology:before {
  content: "";
}

.flaticon-three:before {
  content: "";
}

.flaticon-tool:before {
  content: "";
}

.flaticon-video-camera:before {
  content: "";
}

.flaticon-web:before {
  content: "";
}
/*# sourceMappingURL=index.d0b8ad2a.css.map */
